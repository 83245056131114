import React, { useState } from 'react';
import styles from './side1.module.scss';
import classNames from 'classnames/bind';
import { IoLogoFacebook } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const cx = classNames.bind(styles);

const Side1 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={cx('websiteContainer')}> 
        <div id="section1" className={cx('section1')}>
            <div className={cx('headerDiv')}>
                <p className={cx('headerTitle')}>
                    tonypettas.dk <br className={cx('br850')}/> Træservice
                    
                    <p className={cx('small-header')}>Træfældning</p>
                   
                    <p className={cx('small-header')}>Topkapning</p>
                    
                    <p className={cx('small-header')}>Træpleje</p>
                  
                    <p className={cx('small-header')}>Beskæring</p>
                   
                    <p className={cx('small-header')}>Stubfræsning</p>
                </p>    
                <div className={cx('navbar', { 'mobile-nav': isMenuOpen })}>
                    <div className={cx('desktop-nav')}>
                        <a href="mailto:tonypettas@gmail.com" className={cx('anchorLink')}>Priser</a>
                        <a href="https://www.facebook.com/tonypettas.dk/" className={cx('anchorLink')}>Kontakt</a>
                        <a href="#section4" className={cx('anchorLink')}>Min Virksomhed</a>
                    </div>
                        <button className={cx('menuButton')} onClick={handleMenuToggle}>Menu</button>
                </div>

                  {isMenuOpen && (
                    <div className={cx('menu')}>
                        <button className={cx('menuButtonOpened')} onClick={handleMenuToggle}><IoClose /></button>
                        <a href="#section3" onClick={handleMenuToggle} className={cx('anchorLink')}>Priser</a>
                        <a href="#section2" onClick={handleMenuToggle} className={cx('anchorLink')}>Kontakt</a>
                        <a href="#section4" onClick={handleMenuToggle} className={cx('anchorLink')}>Min virksomhed</a>
                    </div>
                 )}
            </div>
        </div>

        <div id="section3" className={cx('section3')}>
            <div className={cx('section3-header')}>
                    Priser
            </div>
            <div className={cx('section3-description')}>
            Jeg tilbyder gratis besigtigelse, rådgivning og tilbud. Hver opgave vurderes og prissættes individuelt. Typisk består et tilbud af tre separate priser: fældning/opskæring, oprydning/bortkørsel og stubfræsning. Vi taler os frem til den rigtige løsning på netop din opgave!
            </div>
            <div className={cx('section3-content')}>
                <div className={cx('section3offers')}>
                    <div className={cx('section3offersHeader')}>
                        Jeg tilbyder
                    </div>
                    <div className={cx('section3offersList')}>
                        <p className={cx('section3p')}>– Træfældning</p>
                        <p className={cx('section3p')}>– Topkapning</p>
                        <p className={cx('section3p')}>– Træpleje</p>
                        <p className={cx('section3p')}>– Beskæring</p>
                        <p className={cx('section3p')}>– Stubfræsning</p>
                    </div>
                </div>
                <div className={cx('section3other')}>
                <a href="https://www.facebook.com/tonypettas.dk/" className={cx('cta-button2')} role="button">Gratis Tilbud</a>
                <a href="https://www.facebook.com/tonypettas.dk/" className={cx('cta-button2')} role="button"><IoLogoFacebook className={cx('facebook')} /></a>
                <a href="mailto:tonypettas@gmail.com" className={cx('cta-button2')} role="button"><IoMdMail className={cx('facebook')} /></a>
                </div>
            </div>
        </div>
        <div id="section2" className={cx('section2')}>
                <h2 className={cx('cta-title')}>Ekspert i Træfældning</h2>
                <p className={cx('cta-description')}>Med mange års erfaring tilbyder jeg sikker og pålidelig træfældning. Kontakt mig for at få et gratis tilbud i dag.</p>
                <a href="#section4" className={cx('cta-button')} role="button">Få et Gratis Tilbud</a>
        </div>  
        <div id="section4"className={cx('section4')}>
            <div className={cx('section4header')}>Min virksomhed</div>
            <div className={cx('section4text')}>
            Som din lokale træfældningsekspert står jeg klar til at håndtere
             enhver opgave med omhu og ekspertise. Med års erfaring og fokus
              på kvalitet og omsorg sikrer jeg, at dit træfældningsprojekt
               bliver håndteret professionelt og tilfredsstillende. Kontakt
                mig i dag for en gratis konsultation, og lad os sammen skabe 
                et smukt og sikkert udendørs miljø.
            </div>
            <div className={cx('section4images')}>
                <div className={cx('image1')}></div>
                <div className={cx('image2')}></div>
                <div className={cx('image3')}></div>
            </div>
        </div>

        <div className={cx('section5')}>
            <div className={cx('footerp')}>tlf: 24 24 02 63 - CVR: 28002599</div>
        </div>
    </div>
  );
};

export default Side1;

