import logo from './logo.svg';
import Layout from './Layout';
import Side1 from './Side1';
import './App.css';

function App() {
  return (
    <Layout>
      <Side1 />
    </Layout>
  );
}

export default App;
